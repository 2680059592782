<template>
  <div class="supper">
    <div class="holder">
      <!-- <AuthNavbar /> -->
      <!-- <div class="mobile-logo-holder">
        <div class="blue-back-logo">
          <img src="../assets/images/logo-header.svg" alt="" />
        </div>
        <div class="shelta-mobile-logo">
          <img src="../assets/images/logo1.svg" alt="" />
        </div>
      </div> -->
      <div class="login-body">
        <div class="container login-wrap">
          <div class="logo_holder">
            <img src="../assets/images/logo-yellow.svg" alt="" />
          </div>
          <div class="card">
            <div class="card-body my-card-body">
              <div class="login-header">
                <h1>Forgot Password</h1>
              </div>
              <form>
                <div class="form-group email-group">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email address"
                    autocomplete="on"
                    v-model="email"
                    name="email"
                  />
                  <i
                    class="fa fa-user email-icon"
                    id="passwordVisibility"
                    aria-hidden="true"
                  ></i>
                </div>
                <div class="form-group">
                  <button
                    @click.prevent="forgotPassword()"
                    class="btn login-btn btn-block"
                  >
                    Send Recovery Link
                    <i
                      class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                      v-if="loader"
                    ></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="no-account-password">
            <p>
              <router-link class="register-router" to="/signup">
                Don't have an account?
                <span class="register-span">Register</span>
              </router-link>
            </p>
            <p>
              Have an account?
              <span class="have-account"
                ><router-link class="login-router" to="/login"
                  >Login</router-link
                ></span
              >
            </p>
          </div>
        </div>
        <!-- <div class="whatsapp-dialer-icon-holder">
          <img src="../assets/images/whatsapp-icon.svg" alt="whatsapp" />
          <img src="../assets/images/phone-handle.svg" alt="call us" />
        </div> -->
      </div>
      <!-- Footter bigins -->

      <!-- Footer ends -->
    </div>
    <!-- Footter bigins -->
    <Footer />
    <!-- Footer ends -->
  </div>
</template>

<script>
import Footer from "./FooterMain2.vue";
import AuthNavbar from "./AuthNavbar";
import { Service } from "../store/service";
const Api = new Service();

export default {
  name: "ForgotPassword",
  components: {
    // Header,
    Footer,
    AuthNavbar
  },
  data() {
    return {
      loader: false,
      email: ""
    };
  },
  methods: {
    forgotPassword: function() {
      this.loader = true;
      Api.postRequestNoAuth("forgotpassword", {
        email: this.email
      }).then(res => {
        console.log("Forgot password response", res);
        this.loader = false;
        if (res.data.success) {
          return this.$store.commit("setApiSuccess", res.data.success);
        } else if (res.data.error) {
          return this.$store.commit("setApiFailed", res.data.error);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;
// $fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

::placeholder {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.supper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.holder {
  flex-grow: 1;
  // min-height: 100vh;
}
.logo_holder {
  margin-bottom: 3rem;
}
.logo-div {
  background: $primary;
  // clip-path: circle(50% at 50% 0);
  clip-path: circle(50% at 50% -20%);
  margin: auto;
  width: 12rem;
  height: 7rem;
  height: 4rem;
  position: relative;
}
.logo-div > img {
  width: 4.5rem;
  position: relative;
  top: 23%;
}

.my-card-body {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.login-header {
  h1 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }
}

.login-body {
  position: relative;
  padding-top: 6rem;
}

.login-body > div {
  // width: 25rem;
  margin: auto;
}

.login-btn {
  background-color: $yellow;
  color: #ffffff;
  text-transform: uppercase;
}

.no-account-password {
  display: flex;
  justify-content: space-between;
  padding-top: 0.6rem;
}
.no-account-password {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 12px;
    color: #000000;
  }
}

.register-span {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 12px;
  color: $primary;
}
.register-router {
  color: #000000;
  text-decoration: none;
}
.login-router {
  color: $primary;
  text-decoration: none;
  font-weight: bold;
}

// .login-wrap {
//   display: flex;
// }

.email-group {
  position: relative;
}
.email-icon {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 0% !important;
  right: 3% !important;
}

.password-group {
  position: relative;
}
.password-icon {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 0% !important;
  right: 3% !important;
}

.whatsapp-dialer-icon-holder {
  position: relative;
}
.whatsapp-dialer-icon-holder > img:first-child {
  position: relative;
  bottom: 12rem;
  left: 40rem;
}
.whatsapp-dialer-icon-holder > img:nth-child(2) {
  position: relative;
  bottom: 8rem;
  left: 38.4rem;
}

.btn-loader {
  padding: 0 !important;
  font-size: 17px !important;
  margin-left: 2rem !important;
}

// Footer section begins
.footer-holder {
  position: relative;
}
.fa {
  padding: 20px;
  font-size: 30px;
  width: 13.55px !important;
  height: 13.55px !important;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  position: relative;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  // background: #3b5998;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-facebook::before {
  position: absolute;
  left: 38%;
  top: 30%;
  font-size: 1.4rem;
}

.fa-twitter {
  // background: #55acee;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-twitter::before {
  position: absolute;
  left: 30%;
  top: 28%;
  font-size: 1.4rem;
}

.fa-linkedin {
  // background: #007bb5;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-linkedin::before {
  position: absolute;
  left: 30%;
  top: 25%;
  font-size: 1.4rem;
}

.fa-instagram {
  // background: #125688;
  border: 1px solid #fff;
  color: white;
  border-radius: 3px;
}
.fa-instagram::before {
  position: absolute;
  left: 30%;
  top: 25%;
  font-size: 1.4rem;
}
.clip-shape-div {
  clip-path: ellipse(43% 164% at 50% 164%);
  // clip-path: ellipse(35% 164% at 50% 165%);
  background: $primary;
  // position: relative;
}

.copy-right-text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  margin-bottom: 0;
  color: #ffffff;
  padding-bottom: 0.5rem;
}

.help-holder {
  position: absolute;
  right: 2%;
  bottom: 8%;
  width: 89px;
  height: 45px;
  background: $primary;
  border-radius: 22.5px;
}
// Footer section ends

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  .login-body {
    padding-top: 4rem;
  }
  .whatsapp-dialer-icon-holder {
    // position: relative;
    // right: 22%;

    right: 45%;
    justify-content: center;
    margin-bottom: 2rem;
    padding-top: 0.6rem;
  }
  .no-account-password {
    display: block;
  }
  .whatsapp-dialer-icon-holder > img:first-child {
    bottom: 1rem;
    left: 7px;
  }
  .whatsapp-dialer-icon-holder > img:nth-child(2) {
    bottom: 1rem;
    left: 9px;
  }
  .footer-holder {
    padding-top: 10.3rem;
  }
  // .help-holder {
  //   left: 36%;
  //   top: 23%;
  //   position: absolute;
  // }
}
@media only screen and (max-width: 599px) {
  .supper {
    min-height: 100%;
  }
  .login-body {
    padding-top: 4rem;
  }
  // .mobile-logo-holder {
  //   margin: auto;
  //   height: 4rem;
  //   position: relative;
  // }
  // .blue-back-logo > img {
  //   width: 18.3016105417vw;
  // }
  // .shelta-mobile-logo {
  //   position: absolute;
  //   top: 5%;
  //   left: 46%;
  // }
  // .shelta-mobile-logo > img {
  //   width: 8.7115666179vw;
  // }
  .footer-holder {
    padding-top: 10.3rem;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .login-body > div {
    max-width: 29.02625rem !important;
  }
  .mobile-logo-holder {
    display: none;
  }
}
</style>
