<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="#">
        <router-link class="" to="/shelta">
          <img class="" src="../assets/images/home-icon.svg" alt="home" />
        </router-link>
      </a>
      <div class="logo">
        <div class="logo-div">
          <div class="logo-blueDiv-main">
            <img src="../assets/images/logo-header.svg" alt="" />
          </div>
          <div class="shelta-img-log">
            <img src="../assets/images/logo1.svg" alt="" />
          </div>
        </div>
        <!-- <ul class="navbar-nav mr-auto">
        </ul> -->
      </div>
      <div>
        <img src="../assets/images/menu-icon.svg" alt="menu" />
      </div>
      <!-- <button
        class="navbar-toggler"
        type="button"
        
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->
    </nav>
    <!-- Navbar sections ends -->
  </div>
</template>

<script>
export default {
  name: "AuthNavbar",
  methods: {}
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");

.navbar {
  padding-top: 0 !important;
}
.logo {
  margin: auto;
}
.logo-div {
  margin: auto;
  // width: 12rem;
  height: 7rem;
  height: 4rem;
  position: relative;
  bottom: 1rem;
}
.logo-div > img {
  width: 4.5rem;
  position: relative;
  top: 23%;
}
.logo-blueDiv-main > img {
  width: 18.3016105417vw;
}

.shelta-img-log {
  position: absolute;
  top: 35%;
  left: 26%;
}
.shelta-img-log > img {
  width: 8.7115666179vw;
}

@media screen and (max-width: 360px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  .navbar-light,
  .navbar-toggler {
    border-color: transparent;
  }
  .logo-blueDiv-main > img {
    width: 44.301611vw;
  }
  .shelta-img-log {
    top: 28%;
    left: 26%;
  }
  .shelta-img-log > img {
    width: 20.711567vw;
    padding-top: 0.2rem;
  }
}

@media screen and (max-width: 320px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 2) {
  .logo-blueDiv-main > img {
    width: 51.301611vw;
  }
  .shelta-img-log {
    top: 28%;
    left: 30%;
  }
  .shelta-img-div > img {
    width: 15.711567vw;
  }
}
</style>
